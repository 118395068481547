import { RefObject, Dispatch, SetStateAction } from "react";

export default function setupCatalogKeyboardNavigation(dialogType: string, event: KeyboardEvent, inputIsFocused: Element | null, topSearchRef: RefObject<HTMLDivElement>, topRecommendationRef: RefObject<HTMLDivElement>, storeAndProductRef: RefObject<HTMLDivElement>, container: string, setContainer: Dispatch<SetStateAction<"second" | "first">>, updateNavigationSearchFnc: () => void) {
  const MOBILE_PRODUCT_SEARCH_INPUT_ID = "store_search_bar_mobile";
  const DESKTOP_PRODUCT_SEARCH_INPUT_ID = "store_search_bar_desktop";

  switch (dialogType) {
    case 'catalog': {
      if (topSearchRef.current || topRecommendationRef.current || storeAndProductRef.current) {
        // top search
        const topSearchItems = topSearchRef?.current?.querySelectorAll('a') as NodeListOf<HTMLAnchorElement>;
        // top reccos
        const topRecommendationItems = topRecommendationRef?.current?.querySelectorAll('a') as NodeListOf<HTMLAnchorElement>;
        // product results
        const productResultItems = storeAndProductRef.current?.querySelectorAll('a') as NodeListOf<HTMLAnchorElement>;
        const topSearchArray = Array.from(topSearchItems as NodeListOf<HTMLAnchorElement> || [])
        const topRecommendationArray = Array.from(topRecommendationItems as NodeListOf<HTMLAnchorElement> || [])
        const productArray = Array.from(productResultItems as NodeListOf<HTMLAnchorElement> || [])
        const activeTopSearchIndex = topSearchArray.findIndex(child => {
          // find if child is active element
          return child.closest('a') === inputIsFocused;
        })
    
        const activeTopRecommendationIndex = topRecommendationArray.findIndex(child => {
          // find if child is active element
          return child.closest('a') === inputIsFocused;
        })

        const activeProductIndex = productArray.findIndex(child => {
          // find if child is active element
          return child.closest('a') === inputIsFocused;
        })

        switch (event.key) {
          case 'ArrowDown':
          // stop body moving.
          event.preventDefault();
          
          if (topSearchItems && topSearchItems.length > 0 || topRecommendationItems && topRecommendationItems.length > 0) {
            if (activeTopSearchIndex < topSearchItems?.length - 1) {
              topSearchItems[activeTopSearchIndex + 1].closest('a')?.focus()
            } else if (!topRecommendationItems && activeTopSearchIndex === topSearchItems?.length - 1) {
              document.getElementById(DESKTOP_PRODUCT_SEARCH_INPUT_ID)?.focus()
              document.getElementById(MOBILE_PRODUCT_SEARCH_INPUT_ID)?.focus()
            } else if (activeTopSearchIndex === topSearchItems?.length - 1 && topRecommendationItems.length > 0) {
              // move to next div (top recommendations)
              setContainer('second')
              topRecommendationItems[activeTopRecommendationIndex + 1].closest('a')?.focus()
            } else if (!topSearchItems && activeTopRecommendationIndex < topRecommendationItems.length - 1) {
              // no top search, just top reccos
              setContainer('second')  
              topRecommendationItems[activeTopRecommendationIndex + 1].closest('a')?.focus()
            } else if (activeTopRecommendationIndex === topRecommendationItems.length - 1) {
              document.getElementById(DESKTOP_PRODUCT_SEARCH_INPUT_ID)?.focus()
              document.getElementById(MOBILE_PRODUCT_SEARCH_INPUT_ID)?.focus()
            }

            if (container == 'second') {
              if (activeTopRecommendationIndex === topRecommendationItems.length - 1) {
                document.getElementById(DESKTOP_PRODUCT_SEARCH_INPUT_ID)?.focus()
                document.getElementById(MOBILE_PRODUCT_SEARCH_INPUT_ID)?.focus()
                setContainer('first')
              } else {
                topRecommendationItems[activeTopRecommendationIndex + 1].closest('a')?.focus();
              }
            }
          } else if (productResultItems && productResultItems.length > 0) {
            if (activeProductIndex === productResultItems.length -1) {
              document.getElementById(DESKTOP_PRODUCT_SEARCH_INPUT_ID)?.focus()
              document.getElementById(MOBILE_PRODUCT_SEARCH_INPUT_ID)?.focus()
            } else {
              productResultItems[activeProductIndex + 1].closest('a')?.focus()
            }
          }
          break;
          case 'ArrowUp':
            // stop body moving
            event.preventDefault();
            if (activeTopSearchIndex > 0) {
              topSearchItems[activeTopSearchIndex - 1].closest('a')?.focus()
            } else if (activeTopSearchIndex == 0) {
              document.getElementById(DESKTOP_PRODUCT_SEARCH_INPUT_ID)?.focus()
              document.getElementById(MOBILE_PRODUCT_SEARCH_INPUT_ID)?.focus()
            }

            if (productResultItems && productResultItems.length > 0) {
              if (activeProductIndex > 0) {
                productResultItems[activeProductIndex - 1].closest('a')?.focus()
              } else if (activeProductIndex === 0) {
                document.getElementById(DESKTOP_PRODUCT_SEARCH_INPUT_ID)?.focus();
              }
            }

            if (container == 'second') {
              if (activeTopRecommendationIndex > 0) {
                topRecommendationItems[activeTopRecommendationIndex - 1].closest('a')?.focus();
              } else if (topSearchItems && activeTopRecommendationIndex === 0) {
                topSearchItems[topSearchItems.length -1]?.closest('a')?.focus();
                setContainer('first')
              } else if (!topSearchItems && activeTopRecommendationIndex === 0) {
                document.getElementById(DESKTOP_PRODUCT_SEARCH_INPUT_ID)?.focus();
                document.getElementById(MOBILE_PRODUCT_SEARCH_INPUT_ID)?.focus()
              }
            }
          break;
          case 'Enter': {
            const parent = document.getElementById('autocomplete-results-section-wrapper-nav')
            const child = event.target as HTMLElement | null
            
            if (parent?.contains(child)) {
              // allowing one to be []
              if (topSearchArray.length > 0 || topRecommendationArray.length > 0 || productArray.length > 0) {
                const mobileInput = document.getElementById(MOBILE_PRODUCT_SEARCH_INPUT_ID);
                const desktopInput = document.getElementById(DESKTOP_PRODUCT_SEARCH_INPUT_ID);
                (event.target as HTMLInputElement).dispatchEvent(new KeyboardEvent('click', { bubbles: true }));
                ([mobileInput, desktopInput]).map(inputElement => ((inputElement as HTMLInputElement).value));
                desktopInput?.focus();
                event.stopImmediatePropagation();
              }
            }
            break;
          }
          case 'Escape': {
            // go back to the first container after 'escaping'
            setContainer('first');
            const mobileInput = document.getElementById(MOBILE_PRODUCT_SEARCH_INPUT_ID);
            const desktopInput = document.getElementById(DESKTOP_PRODUCT_SEARCH_INPUT_ID);
            inputIsFocused?.closest('a')?.blur();
            document.getElementById(DESKTOP_PRODUCT_SEARCH_INPUT_ID)?.blur();
            // clear both inputs.
            ([mobileInput, desktopInput]).map(inputElement => ((inputElement as HTMLInputElement).value = ""));
            updateNavigationSearchFnc();
            break;
          }
        }
      }
    }
    break;
  }
}

export { setupCatalogKeyboardNavigation }
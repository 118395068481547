import { RefObject } from "react";

export default function setupStoreKeyboardNavigation(
  dialogType: string,
  event: KeyboardEvent,
  storeAndProductRef: RefObject<HTMLDivElement>,
  inputIsFocused: Element | null,
) {
  switch (dialogType) {
    case 'store': {
    if (storeAndProductRef?.current == undefined) { return }
      const storeResultItems = storeAndProductRef.current?.querySelectorAll('a')
      const storeArray = Array.from(storeResultItems)
      const activeStoreIndex = storeArray.findIndex(child => {
        return child.closest('a') === inputIsFocused;
      })

      switch (event.key) {
        case 'ArrowDown':
        // stop body moving.
        event.preventDefault();

        if (activeStoreIndex < storeResultItems.length - 1) {
          storeResultItems[activeStoreIndex + 1].closest('a')?.focus()
        } else if (activeStoreIndex == storeResultItems.length - 1) {
          document.getElementById('search_bar')?.focus();
        }
        break;
        case 'ArrowUp':
          // stop body moving.
          event.preventDefault();
          if (activeStoreIndex > 0) {
            storeResultItems[activeStoreIndex - 1].closest('a')?.focus()
          } else {
            document.getElementById('search_bar')?.focus();
          }
        break;
        case 'Escape':
          document.getElementById('search_bar')?.blur();
        break;
      }
    }
  }
}

export { setupStoreKeyboardNavigation }
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { AutocompleteState, BaseItem } from "@algolia/autocomplete-core";
import { ReactComponent as SearchAlternate } from "images/icons/search-alternate.svg";
import { setupStoreKeyboardNavigation } from "./setup_store_keyboard_navigation";
import { setupCatalogKeyboardNavigation } from "./setup_catalog_keyboard_navigation";

import AutocompleteConfig from "src/components/autocomplete/autocomplete_config";
import debounce from "src/debounce";

type DialogType = "catalog" | "store";
type UpdateNavigationSearchFnc = () => void;

export default function AutocompleteRenderer<T extends BaseItem>({
  autocompleteState,
  autocompleteConfig,
  dialogType,
  updateNavigationSearchFnc,
  expandResults,
}: {
  autocompleteState: AutocompleteState<T>;
  autocompleteConfig: AutocompleteConfig<T>;
  dialogType: DialogType;
  updateNavigationSearchFnc?: UpdateNavigationSearchFnc;
  expandResults?(event: React.MouseEvent, newResultCount: number): void;
}) {
  const [showLoading, setShowLoading] = React.useState<boolean>(false);
  const topSearchRef = React.useRef<HTMLDivElement>(null);
  const topRecommendationRef = React.useRef<HTMLDivElement>(null);
  const storeAndProductRef = React.useRef<HTMLDivElement>(null);
  const [container, setContainer] = useState<'first' | 'second'>('first');
  
  const handleKeyboardNavigation = useCallback((event: KeyboardEvent) => {
    const inputIsFocused = document.activeElement;
    setupStoreKeyboardNavigation(dialogType, event, storeAndProductRef, inputIsFocused);
    if (!updateNavigationSearchFnc) return
    setupCatalogKeyboardNavigation(dialogType, event, inputIsFocused, topSearchRef, topRecommendationRef, storeAndProductRef, container, setContainer, updateNavigationSearchFnc);
  }, [dialogType, container, storeAndProductRef, topSearchRef, topRecommendationRef, setContainer, updateNavigationSearchFnc])

  useEffect(() => {
    window.addEventListener("keydown", handleKeyboardNavigation);
    return () => {
      window.removeEventListener("keydown", handleKeyboardNavigation);
    };
  },[handleKeyboardNavigation]);

  const [debounceLoading, cancelDebouncedLoading] = useMemo(() => debounce(setShowLoading, 200), []);

  const collectionFromId = React.useCallback((sourceId: string) => autocompleteState.collections.find(collection => collection.source.sourceId === sourceId), [autocompleteState.collections]);
  const emptyQueryCollection = React.useMemo(() => collectionFromId(autocompleteConfig.emptyQueryCollectionId), [collectionFromId, autocompleteConfig.emptyQueryCollectionId]);
  const queryCollection = React.useMemo(() => collectionFromId(autocompleteConfig.queryCollectionId), [collectionFromId, autocompleteConfig.queryCollectionId]);

  useEffect(() => {
    if (autocompleteState.status == "loading") {
      debounceLoading(true);
    }
    if (autocompleteState.status == "idle") {
      cancelDebouncedLoading();
      setShowLoading(false);
    }
  }, [autocompleteState.status, debounceLoading, cancelDebouncedLoading]);

  const renderEmptyQueryResults = React.useMemo(() => {
    return (
      <div className="list has-hoverable-items" ref={storeAndProductRef}>
        <div className="space-pl-md space-pr-md space-pb-xs">
          <span className="label-text description">{autocompleteConfig.emptyQueryCollectionTitle}</span>
        </div>
        {emptyQueryCollection?.items.map((item, index) => autocompleteConfig.itemComponent(item, index))}
      </div>
    );
  }, [emptyQueryCollection?.items, autocompleteConfig])

  const fullResultCount = autocompleteState.context.nbProducts as number | undefined;

  const noResults = React.useMemo(() => {
    return (
      <div className="list">
        <span className="list-item">No results</span>
      </div>
    )
  }, [])

  const renderSearchResults = React.useMemo(() => {
    return (
      <div className="list has-hoverable-items" ref={storeAndProductRef}>
        {queryCollection?.items.map((item, index) => autocompleteConfig.itemComponent(item, index))}
        {autocompleteConfig.searchResultsPath(autocompleteState.query) ?
          <a className="list-item" href={autocompleteConfig.searchResultsPath(autocompleteState.query)}>
            <div className="is-flexbox space-p-xs">
              <SearchAlternate className="icon streamline-icon is-medium" />
            </div>

            <span className="label-text">All results for <b>{autocompleteState.query}</b></span>
          </a> : <></>
        }

        {fullResultCount && autocompleteConfig.numResults >= fullResultCount ?
          <></>
        :
        fullResultCount && expandResults ?
        <div className="container">
          <a href="" onClick={event => expandResults(event, fullResultCount)} onMouseDown={(event) => event.preventDefault()} className="space-mt-sm button is-full-width">Show all {fullResultCount > 999 ? '999+' : fullResultCount} results</a>
        </div>
        : <></> 
        }
      </div>
    );
  }, [autocompleteState, queryCollection?.items, autocompleteConfig, fullResultCount, expandResults]);

  const topSearchesResults = useMemo(() => {
    return (
      <section className="space-pt-none space-pb-xs">
        <div className="space-pl-md space-pr-md space-pb-xs">
          <span className="label-text description">Popular searches</span>
        </div>

        <div className="is-flexbox flex-wrap is-flex-gap-xs space-pl-md space-pr-md space-pb-xs" ref={topSearchRef}>
          {autocompleteConfig.topSearchResult()}
        </div>

        {autocompleteConfig.topRecommendationResult().length >= 3 ? <hr className="space-mt-xs space-mb-xs" /> : <></>}
      </section>
    )
  }, [autocompleteConfig])

  const topRecommendationsResult = useMemo(() => {
    return (
      <section className="space-pt-none space-pb-none">
        <div className="space-pl-md space-pr-md space-pb-xs">
          <span className="label-text description">Recommended products</span>
        </div>

        <div className="has-hoverable-items list" id="top-recommendation" ref={topRecommendationRef}>
          {autocompleteConfig.topRecommendationResult()}
        </div>
      </section>
    )
  }, [autocompleteConfig])

  const renderingTopRecommendationsAndTopSearch = useMemo(() => {
    return <>
      {topSearchesResults}
      {topRecommendationsResult}
    </>
  }, [topRecommendationsResult, topSearchesResults])

  const renderResultsLoadingPlaceholder = React.useMemo(() => {
    return (
      <div className="container">
        <div className="skeleton is-heading"></div>
        <div className="skeleton is-paragraph"></div>
        <div></div>
      </div>
    )
  },[])

  const autocompleteResults = React.useCallback(() => {
    if (autocompleteConfig.topSearchResult().length > 2 && autocompleteConfig.topRecommendationResult().length > 0 && autocompleteState.query.length == 0) {
      return renderingTopRecommendationsAndTopSearch;
    } else if (autocompleteConfig.topSearchResult().length > 2 && autocompleteState.query.length == 0) {
      return topSearchesResults;
    } else if (autocompleteConfig.topRecommendationResult().length >= 3 && autocompleteState.query.length == 0) {
      return topRecommendationsResult;
    } else if (emptyQueryCollection && emptyQueryCollection.items.length == 0) {
      return <></>;
    } else if (emptyQueryCollection && emptyQueryCollection.items.length > 0) {
      return renderEmptyQueryResults;
    } else if (autocompleteState.status == 'loading') {
      return renderResultsLoadingPlaceholder;
    } else if (queryCollection?.items?.length) {
      return renderSearchResults;
    } else {
      return noResults;
    }
  }, [noResults, emptyQueryCollection, renderEmptyQueryResults, renderSearchResults, queryCollection, topSearchesResults, autocompleteState, autocompleteConfig, topRecommendationsResult, renderingTopRecommendationsAndTopSearch, renderResultsLoadingPlaceholder]);

  return showLoading ? renderResultsLoadingPlaceholder : autocompleteResults();
}
